import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import Bg from "./bg.png";


export const LottiAnimation = ({ size, animationData }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <LottieContainer size={size}>
            <Lottie
                className="lottie-div"
                options={defaultOptions}
                height={size}
                width={size}
            />
        </LottieContainer>
    );
}

const LottieContainer = styled.div`
background: url(${Bg});
background-repeat: no-repeat;
background-size: contain;
height: 56px;
width: 56px;
display: flex;
justify-content: center;
align-items: center;
`;