import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../../components/layout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  DopeMerchIcon,
  HealthInsuranceIcon,
  InternetIcon,
  MentalHealthIcon,
  PaidLeaveIcon,
  SharesIcon,
} from "../../images/SpecialIcons";
import SwiperComponent from "../../components/swiper";
import {
  ArrowRightShortIcon,
  CancelIcon,
  CaretDownIcon,
  CaretRightIcon,
  ExperienceIcon,
  LocationIcon,
  SearchIcon,
} from "../../images/Icons";

import Hero from "../../images/inside-moniepoint/hero.png";
import HeroSm from "../../images/inside-moniepoint/hero-sm.png";
import SpecialBg from "../../images/special-bg.png";

import GritImage from "../../images/inside-moniepoint/grit.gif";
import CustomerEmpathyImage from "../../images/inside-moniepoint/empathy.gif";
import TechnicalDepthImage from "../../images/inside-moniepoint/technical-depth.gif";
import CandourImage from "../../images/inside-moniepoint/candour.gif";

import WorkFromAnywhere from '../../images/inside-moniepoint/animations/Work-from-anywhere.json';
import PensionAnim from '../../images/inside-moniepoint/animations/Pension.json';
import TeamBuilding from '../../images/inside-moniepoint/animations/Team-Building.json';
import Portfolio from '../../images/inside-moniepoint/animations/Portfolio-of-projects.json';
import RemoteWork from '../../images/inside-moniepoint/animations/wallet.json';
import PaidLeave from '../../images/inside-moniepoint/animations/Leave.json';
import HealthInsurance from '../../images/inside-moniepoint/animations/Health.json';
import Internet from '../../images/inside-moniepoint/animations/Internet.json';
import Shares from '../../images/inside-moniepoint/animations/Shares.json';
import MentalHealth from '../../images/inside-moniepoint/animations/Mental-Health.json';
import { LottiAnimation } from "../../images/inside-moniepoint/animations";
import { Dropdown } from "react-bootstrap";
import useIsMobile from "../../hooks/useMobile";
import { getAllJobs } from "../../helpers/functions";
import { Helmet } from "react-helmet";

const InsideMoniepointPage = ({ data }) => {
  const perks = [
    { name: "inside_moniepoint.perks.work_anywhere", icon: <LottiAnimation size={40} animationData={WorkFromAnywhere} />, isLottie: true },
    { name: "inside_moniepoint.perks.pension", icon: <LottiAnimation size={40} animationData={PensionAnim} />, isLottie: true },
    { name: "inside_moniepoint.perks.paid_leave", icon: <LottiAnimation size={40} animationData={PaidLeave} />, isLottie: true },
    {
      name: "inside_moniepoint.perks.health_insurance",
      icon: <LottiAnimation size={40} animationData={HealthInsurance} />, isLottie: true
    },
    { name: "inside_moniepoint.perks.porfolio", icon: <LottiAnimation size={80} animationData={Portfolio} />, isLottie: true },
    { name: "inside_moniepoint.perks.mental_health", icon: <LottiAnimation size={80} animationData={MentalHealth} />, isLottie: true },
    { name: "inside_moniepoint.perks.internet", icon: <LottiAnimation size={40} animationData={Internet} />, isLottie: true },
    { name: "inside_moniepoint.perks.comapny_shares", icon: <LottiAnimation size={40} animationData={Shares} />, isLottie: true },
    { name: "inside_moniepoint.perks.remote_work", icon: <LottiAnimation size={140} animationData={RemoteWork} />, isLottie: true },
    { name: "inside_moniepoint.perks.tba", icon: <LottiAnimation size={60} animationData={TeamBuilding} />, isLottie: true },
    { name: "inside_moniepoint.perks.dope_merch", icon: DopeMerchIcon },
  ];

  const peopleStories = data?.peopleStories?.nodes;

  return (
    <Layout pageTitle="Inside Moniepoint" type={'mobile_transparent'}>
      <Helmet>
        <meta property="og:image" content={Hero} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <HeroContainer mdSrc={Hero} smSrc={HeroSm}>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <div className="hero-title">
              <Trans>inside_moniepoint.hero_title</Trans>
            </div>
            <Link to="/inside-moniepoint#roles" className="btn btn-primary">
              <Trans>inside_moniepoint.hero_cta</Trans>
            </Link>
          </div>
        </div>
      </HeroContainer>
      <TheTeamContainer>
        <div className="content">
          <h1 className="section-title">
            <Trans>inside_moniepoint.team_title</Trans>
          </h1>
          <p className="section-description">
            <Trans>inside_moniepoint.team_description</Trans>
          </p>
          <iframe
            className="team-video"
            width="100%"
            src="https://www.youtube.com/embed/VH7EdC3E_w0"
            title="You don&#39;t have to join us - but here&#39;s how to make your career dreams come true at Moniepoint."

            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          // allowFullScreen
          ></iframe>

          <h2 className="roles-title" id="roles">
            <Trans>inside_moniepoint.open_roles</Trans>
          </h2>
          <p className="roles-description">
            <Trans>inside_moniepoint.roles_description</Trans>
          </p>
          <JobBoard />
        </div>
      </TheTeamContainer>
      <TeamDetailsCardContainer>
        <div className="content">
          <div className="details-section">
            <h1 className="title">
              <Trans>inside_moniepoint.team_details_title</Trans>
            </h1>
            <p className="description">
              <Trans>inside_moniepoint.team_details_description</Trans>
            </p>
          </div>
        </div>
      </TeamDetailsCardContainer>
      <TeamDetailsContainer>
        <div className="content">
          <div className="details-section">
            <div className="details">
              <div className="details-text">
                <div className="details-title">
                  <Trans>inside_moniepoint.team_details_title_1</Trans>
                </div>
                <div className="details-description">
                  <Trans>inside_moniepoint.team_details_description_1</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../../images/inside-moniepoint/details-1.png"
                className="details-image grow"
              />
            </div>
            <div className="details swap">
              <div className="details-text">
                <div className="details-title">
                  <Trans>inside_moniepoint.team_details_title_2</Trans>
                </div>
                <div className="details-description">
                  <Trans>inside_moniepoint.team_details_description_2</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../../images/inside-moniepoint/details-2.png"
                className="details-image grow"
              />
            </div>
            <div className="details">
              <div className="details-text">
                <div className="details-title">
                  <Trans>inside_moniepoint.team_details_title_3</Trans>
                </div>
                <div className="details-description">
                  <Trans>inside_moniepoint.team_details_description_3</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../../images/inside-moniepoint/details-3.png"
                className="details-image grow"
              />
            </div>
            <div className="details swap">
              <div className="details-text">
                <div className="details-title">
                  <Trans>inside_moniepoint.team_details_title_4</Trans>
                </div>
                <div className="details-description">
                  <Trans>inside_moniepoint.team_details_description_4</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../../images/inside-moniepoint/details-4.png"
                className="details-image grow"
              />
            </div>
            <div className="details">
              <div className="details-text">
                <div className="details-title">
                  <Trans>inside_moniepoint.team_details_title_5</Trans>
                </div>
                <div className="details-description">
                  <Trans>inside_moniepoint.team_details_description_5</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../../images/inside-moniepoint/details-5.png"
                className="details-image grow"
              />
            </div>
          </div>
        </div>
      </TeamDetailsContainer>
      <CoreValuesContainer>
        <div className="content">
          <h1 className="title">
            <Trans>inside_moniepoint.our_core_values</Trans>
          </h1>
          <CoreValueComponent />
        </div>
      </CoreValuesContainer>
      <PerksContainer>
        <div className="content">
          <h1 className="title">
            <Trans>inside_moniepoint.perks_title</Trans>
          </h1>
          <h6 className="description">
            <Trans>inside_moniepoint.perks_description</Trans>
          </h6>

          <div className="perks-section">
            {perks.map((perk, index) => (
              <div className="perk" key={index}>
                {perk.isLottie ? perk.icon : <perk.icon />}
                <h6 className="perk-text">
                  <Trans>{perk.name}</Trans>
                </h6>
              </div>
            ))}
          </div>
        </div>
      </PerksContainer>
      {peopleStories?.length > 0 && <OurThinkingContainer>
        <div className="content">
          <h1 className="title">
            <Trans>inside_moniepoint.our_thinking</Trans>
          </h1>
          <div className="thinking-card-section">
            <SwiperComponent
              width={273}
              loop={true}
              autoplay
              slidesPerView={"auto"}
              breakpoints={{
                768: {
                  spaceBetween: 24,
                },
              }}
            >
              {
                peopleStories?.map((post, i) => (
                  <div className="thinking-card grow" key={i}>
                    <div className="card-image">
                      <img

                        alt={post?.title}
                        src={post?.coverImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
                      />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <Trans>{post?.title}</Trans>
                      </h4>
                      <p className="card-description truncate">
                        <Trans>
                          {post?.author}
                        </Trans>
                      </p>

                      <Link to={`/blog/${post?.slug}`} className="card-link">
                        <span>
                          <Trans>read_more</Trans> <ArrowRightShortIcon />
                        </span>
                      </Link>
                    </div>
                  </div>
                ))
              }


            </SwiperComponent>
          </div>
        </div>
      </OurThinkingContainer>}
    </Layout>
  );
};

export default InsideMoniepointPage;

const JobBoard = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [inputs, setInputs] = React.useState({ team: "", search: "", location: "" });
  const [jobs, setJobs] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [selectedJob, setSelectedJob] = React.useState(null);
  const [maxMobileCount, setMaxMobileCount] = React.useState(3);

  useEffect(() => {
    async function getJobs() {
      setLoading(true);
      const allJobs = await getAllJobs();
      setJobs(allJobs.jobs.map(job => ({ ...job, location: job.location.name, department: job.departments[0].name, job_id: job.id })))
      setLoading(false);
    }
    getJobs();
  }, [])


  useEffect(() => {
    let departmentCounts = jobs.map(t => t.department).reduce((map, val) => { map[val] = (map[val] || 0) + 1; return map }, {});
    const departments = Object.entries(departmentCounts).map((item) => ({ name: item[0], count: item[1] }));
    setTeams(departments)
  }, [jobs])

  const sideMenuFilteredJobs = () => {
    return jobs.filter(filterLocation).filter((job) => job.title.toLowerCase().includes(inputs.search.toLowerCase()))
  }


  const filteredJobs = () => {
    return jobs.filter(filterLocation).filter((job) => inputs.team ? job.department === inputs.team : true).filter((job) => job.title.toLowerCase().includes(inputs.search.toLowerCase()))
  }

  const filterLocation = (job) => {
    if (inputs.location === 'Others' && job.location === 'Remote') return false;
    if (inputs.location === 'Remote' && job.location !== 'Remote') return false;
    return true
  }

  const getDeptJobCount = (name) => sideMenuFilteredJobs().filter(t => t.department === name).length

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const changeTeam = (team) =>
    setInputs((values) => ({ ...values, team: team.name }));

  const clearInput = () => setInputs((values) => ({ ...values, search: "" }));

  const changeLocation = (location) =>
    setInputs((values) => ({ ...values, location }));

  const SeeMore = () => setMaxMobileCount(value => value += 3)

  return (
    <JobBoardContainer>
      {loading ? <div className="loading-section">
        <div className="loader">
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58 29C58 45.0163 45.0163 58 29 58C12.9837 58 0 45.0163 0 29C0 12.9837 12.9837 0 29 0C45.0163 0 58 12.9837 58 29ZM5.8 29C5.8 41.813 16.187 52.2 29 52.2C41.813 52.2 52.2 41.813 52.2 29C52.2 16.187 41.813 5.8 29 5.8C16.187 5.8 5.8 16.187 5.8 29Z" fill="#E5E9F2" />
            <path d="M29.2531 0.00110295C36.9125 0.0679454 44.2344 3.16235 49.6203 8.60875C55.0062 14.0551 58.0186 21.4112 57.9999 29.0709C57.9812 36.7305 54.9329 44.0718 49.5204 49.4918C44.108 54.9118 36.771 57.9704 29.1114 57.9998L29.0891 52.1998C35.2168 52.1763 41.0864 49.7294 45.4163 45.3934C49.7463 41.0574 52.185 35.1844 52.1999 29.0567C52.2149 22.9289 49.805 17.0441 45.4962 12.687C41.1875 8.32988 35.33 5.85436 29.2025 5.80088L29.2531 0.00110295Z" fill="#0361F0" />
          </svg>
        </div>
      </div> :
        <>
      <form className="actions-container">
        <div className="jobboard-input-wrapper">
          <input
            required
            disabled={loading}
            className="jobboard-input"
            type="text"
            name="search"
            value={inputs.search}
            placeholder={t("inside_moniepoint.roles.search_by_job_title")}
            onChange={handleChange}
          />
          <span onClick={clearInput} className="jobboard-input-icon">
            {inputs.search ? <CancelIcon /> : <SearchIcon />}
          </span>
        </div>
        {/*
        <Dropdown as={SelectInputContainer}>
          <Dropdown.Toggle as={SelectInputButton} className={inputs.location && "active"}>
            <span>{inputs.location || 'Locations'}</span>
            <CaretDownIcon color="rgba(11, 50, 117, 0.5)" />
          </Dropdown.Toggle>

          <Dropdown.Menu as={SelectInputOptionsContainer} className="fadeIn-section">
            <Dropdown.Item onClick={() => changeLocation("")} as="div" className={!inputs.location && "active"}>All</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLocation("Remote")} as="div" className={inputs.location === "Remote" && "active"}>Remote</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLocation("Others")} as="div">Other Locations</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}



        <Dropdown as={SelectInputContainer} className="select-teams">
          <Dropdown.Toggle as={SelectInputButton} className={inputs.team && "active"}>
            <span>{inputs.team || 'All Teams'}</span>
            <CaretDownIcon color="rgba(11, 50, 117, 0.5)" />
          </Dropdown.Toggle>

          <Dropdown.Menu as={SelectInputOptionsContainer} className="fadeIn-section">
            <Dropdown.Item onClick={() => changeTeam("")} as="div" className={!inputs.team && "active"}>All Teams ({jobs.length})</Dropdown.Item>
            {teams.map((team, index) => (
              <Dropdown.Item key={index} onClick={() => changeTeam(team)} as="div" className={inputs.team === team.name && "active"}>{team.name} ({team.count})</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </form>
      <div className="jobboard-divider"></div>
      {
        selectedJob ?
          <div className="jobboard-body">
            {/* {parsedHTML()} */}
          </div>
          :
          <div className="jobboard-body">
            <div className="jobboard-teams">
              {!loading && <> <div
                className={`jobboard-team ${inputs.team === "" ? "selected" : ""}`}
                onClick={() => changeTeam({ name: "" })}
              >
                <span className="name">All Teams</span>
                <span className="count">
                  {sideMenuFilteredJobs().length}
                </span>
              </div>
                {teams.map((team, index) => (
                  <div
                    className={`jobboard-team ${team.name === inputs.team ? "selected" : ""
                      }`}
                    key={index}
                    onClick={() => changeTeam(team)}
                  >
                    <span className="name">{team.name}</span>
                    <span className="count">{getDeptJobCount(team.name)}</span>
                  </div>
                ))}
              </>
              }
            </div>


            <div className="jobboard-listing-container">
              {(inputs.search.length > 0 || inputs.teams) && !loading && (
                <div className="jobboard-search">
                  <span>
                    <Trans>search_result</Trans>
                  </span>
                  <span className="count">{filteredJobs().length}</span>
                </div>
              )}


              <div className="jobboard-listings">
                {filteredJobs().length === 0 && (
                  <div className="job-listings-empty">
                    <StaticImage
                      alt="..."
                      src="../../images/empty.png"
                      className="empty-image"
                    />
                    <h4 className="empty-title">
                      <Trans>no_results_found</Trans>
                    </h4>
                    <p className="empty-description">
                      <Trans>no_results_found_description</Trans>
                    </p>
                  </div>
                )}

                {filteredJobs().slice(0, isMobile ? maxMobileCount : jobs.length).map((job, index) => (
                  <Link to={`/inside-moniepoint/jobs?id=${job.job_id}`} className="jobboard-listing" key={index}>
                    <div>
                      <h4 className="job-title">{job?.title}</h4>
                      {/* <div className="job-details">
                    <ExperienceIcon color="rgba(11, 50, 117, 0.3)" />
                    <span>
                          <Trans>experience</Trans>: {job?.experince || 'N/A'}
                    </span>
                  </div> */}
                      <div className="job-details">
                        <LocationIcon color="rgba(11, 50, 117, 0.3)" />
                        <span>
                          <Trans>Office</Trans>: {job?.location}
                        </span>
                      </div>
                    </div>
                    <div className="jobboard-listing-icon">
                      <CaretRightIcon color="rgba(11, 50, 117, 0.3)" />
                    </div>
                  </Link>
                ))}
              </div>

              {maxMobileCount < filteredJobs().length && <div className="job-listing-show-more" onClick={SeeMore}>
                <span>
                  <Trans>show_more</Trans>
                </span>
                <CaretDownIcon color="rgba(3, 97, 240, 1)" />
              </div>}
            </div>
          </div>
      }
        </>}
    </JobBoardContainer>
  );
};

const CoreValueComponent = () => {
  const coreValues = [
    {
      id: "01",
      name: "inside_moniepoint.core_values.grit",
      description: "inside_moniepoint.core_values.grit_description",
      image: GritImage,
    },
    {
      id: "02",
      name: "inside_moniepoint.core_values.customer_empathy",
      description: "inside_moniepoint.core_values.customer_empathy_description",
      image: CustomerEmpathyImage,
    },
    {
      id: "03",
      name: "inside_moniepoint.core_values.technical_depth",
      description: "inside_moniepoint.core_values.technical_depth_description",
      image: TechnicalDepthImage,
    },
    {
      id: "04",
      name: "inside_moniepoint.core_values.candour",
      description: "inside_moniepoint.core_values.candour_description",
      image: CandourImage,
    },
  ];

  return (
    <CoreValueComponentContainer>
      {
        coreValues.map((value, i) => (
          <div className="value-card" key={value.id}>
            <img
              alt="..."
              src={value.image}
              className="card-image"
            />
            <div className="card-body">
              <h4 className="card-title">
                <Trans>{value.name}</Trans>
              </h4>
              <p className="card-description">
                <Trans>{value.description}</Trans>
              </p>
            </div>
          </div>
        ))
      }
    </CoreValueComponentContainer>
  );
};

export const query = graphql`

  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    peopleStories : allGraphCmsPost(
    sort: {fields: date, order: DESC}
    filter: {category: {categoryName: {eq: "People"}}}
  ) {
    nodes {
      slug
      title
      author
      coverImage {
        id
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  }
`;



const HeroContainer = styled.section`
  background: url(${(props) => props.mdSrc});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 497px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 45%);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }

    .hero-content {
      color: white;

      .hero-title {
        font-family: "Founders Grotesk";
        font-size: 64px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 22px;
        max-width: 556px;
      }

      .hero-image {
        border-radius: 20px;
        height: 447px;
        width: 480px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    /* background: url(${(props) => props.smSrc}); */
    background-position: 75%;
    background-size: cover;

    .hero-mask {
      /* display: none */
      background:  rgba(0, 0, 0, 0.4)
    }

    .hero-content-wrapper {
      padding: 48px 20px;
      height: 497px;

      .hero-content {
        flex-direction: column;
        gap: 48px;

        .hero-title {
          font-size: 48px;
          line-height: 53px;
        }

        .hero-image {
          display: none;
        }
      }
    }
  }
`;

const TheTeamContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 75px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 75px 60px;
    }

    .section-title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 622px;
      margin-bottom: 14px;
    }
    .section-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      /* max-width: 622px; */
      margin-bottom: 48px;
    }
    .roles-title {
      font-family: "Founders Grotesk";
      font-size: 35px;
      font-weight: 500;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 14px;
    }

    .roles-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      /* max-width: 698px; */
    }

    .team-video {
      border-radius: 22px;
      aspect-ratio: 16/9;
      width: 100%;
      max-width: 1440px;
      margin-bottom: 48px;

      .html5-video-player {
        background-color: white !important;
      }

      @supports not (aspect-ratio: 1) {
        height: 408px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 75px 20px;

      .section-title {
        font-size: 35px;
        line-height: 41px;
      }
      .section-description {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 32px;
      }

      .roles-title {
        font-size: 35px;
        line-height: 41px;
      }
      .roles-description {
        font-size: 17px;
        line-height: 28px;
      }
    }
  }
`;

const TeamDetailsCardContainer = styled.section`
  background: rgba(3, 87, 238, 0.03) url(${SpecialBg});
  background-size: contain;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 86px 151px 126px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 59px 60px;
    }

    .details-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 14px;
      }

      .description {
        font-size: 17px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 480px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background: rgba(11, 50, 117, 1) url(${SpecialBg});
    background-size: cover;

    .content {
      padding: 68px 20px;
      color: white;

      .details-section {
        .title {
          font-size: 32px;
          line-height: 37.76px;
          letter-spacing: -0.005em;
          margin-bottom: 8px;
        }

        .description {
          font-size: 16px;
          line-height: 19.36px;
        }
      }
    }
  }
`;

const TeamDetailsContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    .section-title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 14px;
    }

    .section-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 480px;
      margin-bottom: 48px;
    }

    .details-section {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;

      &.swap {
        .details-text {
          order: 2;
        }
      }

      .details-text {
        width: fit-content;
      }

      .details-title {
        font-family: "Founders Grotesk";
        font-size: 35px;
        font-weight: 500;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 14px;
        max-width: 389px;
      }
      .details-description {
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }
      .details-image {
        border-radius: 16px;
        height: 364px;
        max-width: 554px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 75px 20px;

      .section-title {
        font-size: 35px;
        line-height: 41px;
      }
      .section-description {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 32px;
      }
      .details-section {
        gap: 64px;
      }
      .details {
        flex-direction: column;
        gap: 14px;

        .details-text {
          order: 2;
        }

        .details-title {
          font-size: 32px;
          line-height: 38px;
        }

        .details-description {
          font-size: 16px;
          line-height: 28px;
        }

        .details-image {
          border-radius: 16px;
          height: 330px;
          max-width: 100%;
        }
      }
    }
  }
`;

const CoreValuesContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    padding: 64px 150px 95.89px;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1024px) {
      padding: 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 48px;
    }
  }

  @media only screen and (max-width: 768px) {
    background-color: rgba(3, 87, 238, 0.03);

    .content {
      padding: 60px 20px;

      .title {
        font-size: 35px;
        line-height: 41px;
      }
    }
  }
`;

const PerksContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    padding: 92px 150px;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1024px) {
      padding: 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: left;
    }
    .description {
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      /* max-width: 540px; */
    }

    .perks-section {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px 16px;

      .perk {
        padding: 40px 24px;
        border-radius: 8px;
        border: 1px solid rgba(3, 87, 238, 0.1);

        .perk-text {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 400;
        line-height: 28.32px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 24px;
      }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 60px 20px;

      .title {
        font-size: 35px;
        line-height: 41px;
      }

      .perks-section {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .perk {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid rgba(3, 87, 238, 0.1);

        .perk-text {
font-size: 17px;
margin-top: 16px;

        }

      }

      
      }
    }
  }
`;

const OurThinkingContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    padding: 92px 150px;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1024px) {
      padding: 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: left;
    }

    .thinking-card-section {
      margin-top: 32px;
    }

    .thinking-card {
      width: 273px;
      border-radius: 8px;
      border: 1px solid rgba(3, 87, 238, 0.05);

      .card-image {
        width: 100%;
        border-radius: 16px 16px 0 0;
        height: 150px;
        overflow: hidden;

        img {
          width: 100%;
        border-radius: 16px 16px 0 0;
        height: 150px;
        object-fit: cover;
        }
      }

      .card-body {
        background-color: white;
        padding: 24px 24px 44px;
        border-radius: 0 0 16px 16px;
      }

      .card-title {
        font-family: "Founders Grotesk";
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
      }

      .card-description {
        font-size: 13px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 16px;
      }

      .card-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(3, 97, 240, 1);
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;

        svg {
          path {
            fill: rgba(3, 97, 240, 1);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .content {
      padding: 92px 20px;
    }
  }
`;

const JobBoardContainer = styled.div`
  background-color: rgba(247, 250, 254, 1);
  border: 1px solid rgba(11, 50, 117, 0.05);
  padding: 50px 39px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;

  .loading-section {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      margin: auto;
      animation: spin 1s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .jobboard-input-wrapper,
    .jobboard-select-wrapper {
      background-color: white;
      border-radius: 8px;
      padding: 14px 24px;
      max-width: 447px;
      width: 100%;
      position: relative;

      .jobboard-input-icon {
        position: absolute;
        right: 24px;
      }
    }

    .jobboard-input,
    .jobboard-select {
      all: unset;
      font-family: "Inter";
      font-size: 17px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      width: 100%;

      ::placeholder {
        color: rgba(11, 50, 117, 0.5);
        opacity: 1;
      }
    }

    select:invalid,
    select:disabled {
      color: rgba(11, 50, 117, 0.5);
      opacity: 1;
    }

    option[value=""][disabled] {
      /* display: none; */
    }

    .jobboard-select-wrapper {
      max-width: 215px;
    }

    .select-teams {
      display: none;
    }
  }

  .jobboard-divider {
    width: 100%;
    height: 1px;
    background: rgba(11, 50, 117, 0.1);
    display: none;
  }

  .jobboard-body {
    display: flex;
    gap: 32px;
    height: 100%;
    position: relative;

    .jobboard-teams {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 16px;
      width: 319px;
      padding: 26px 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .jobboard-team {
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

        .name {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(0, 0, 0, 1);
          width: max-content;
        }

        .count {
          padding: 3px 6px;
          background: rgba(11, 50, 117, 0.1);
          border-radius: 4.42px;
          font-family: "Inter";
          font-size: 11px;
          font-weight: 500;
          line-height: 13px;
          letter-spacing: 0em;
          text-align: center;
          color: rgba(11, 50, 117, 1);
        }

        &.selected,
        :hover {
          .name {
            color: rgba(3, 97, 240, 1);
          }
          .count {
            background: rgba(3, 97, 240, 1);
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .jobboard-listing-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .job-listing-show-more {
        display: none;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(3, 97, 240, 1);
        margin: 16px auto;
        align-items: center;
      }

      .jobboard-search {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;

        .count {
          padding: 3px 6px;
          background: rgba(11, 50, 117, 0.1);
          border-radius: 4.42px;
          font-family: "Inter";
          font-size: 11px;
          font-weight: 500;
          line-height: 13px;
          letter-spacing: 0em;
          text-align: center;
          color: rgba(11, 50, 117, 1);
        }
      }

      .jobboard-listings {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        overflow-y: scroll;
        height: 560px;
        padding-right: 15px;
        cursor: pointer;

        .jobboard-listing,
        .job-listings-empty {
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
          border-radius: 8px;
          padding: 20px 24px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          .job-title {
            font-family: "Founders Grotesk";
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 4px;
            color: black;
          }

          .job-details {
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .job-listings-empty {
          justify-content: center;
          flex-direction: column;
          padding-top: 52px;
          padding-bottom: 35px;
          text-align: center;

          .empty-image {
            width: 86px;
            margin-bottom: 15px;
          }

          .empty-title {
            font-family: "Founders Grotesk";
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
          }
          .empty-description {
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        &.show {
          padding-right: 0;
          overflow-y: visible;
          height: 100%;
        }

        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.05);
          border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(11, 50, 117, 0.2);
          border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(11, 50, 117, 0.1);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px -20px;
    padding: 39px 21px;
    width: 100vw;
    border-radius: 0px;
    border: 0px;
    gap: 24px;

    .actions-container {
      flex-direction: column;

      .jobboard-input-wrapper,
      .jobboard-select-wrapper {
        max-width: none;
      }

      .select-teams {
        display: block;
      }
    }

    .jobboard-divider {
      display: block;
    }

    .jobboard-body {
      flex-direction: column;

      .jobboard-teams {
        display: none;
      }

      .jobboard-listing-container {
        .jobboard-listings {
          .job-listings-empty {
            height: 355px;
            align-items: center;
          }

          overflow-y: hidden;
          height: max-content;
          padding-right: 0px;
        }

        .job-listing-show-more {
          display: flex;
        }
      }
    }
  }
`;

const CoreValueComponentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;


  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .value-card {
    width: 100%;
    background-color: white;
    border-radius: 16px;

    .card-image {
      width: 100%;
      max-height: 150px;
      height: auto;
      border-radius: 16px 16px 0 0;
    }

    .card-body {
      padding: 33px 24px 24px;

      .card-title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 14px;
      }

      .card-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);

    .value-card{
      .card-image{
        max-height: none;
      }

      .card-body{


        .card-decription{
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;
const SelectInputContainer = styled.div`
width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 215px;
  }
`;
const SelectInputButton = styled.div`
width: 100%;
background: rgba(255, 255, 255, 1);
border-radius: 8px;
color: rgba(11, 50, 117, 0.5);
padding: 14px 24px;
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
font-size: 17px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

&.active {
  color: rgba(0, 0, 0, 1);
}


:after{
  display: none !important;
}
`;
const SelectInputOptionsContainer = styled.div`
width: 100%;
border: none !important;
border-radius: 8px;
transform: translate3d(0px, 60px, 0px) !important;
box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
padding: 8px !important;

.dropdown-item {
  padding: 8px 24px;
  font-size: 14px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
border-radius: 8px;
cursor: pointer;

&.active{
  background: rgba(11, 50, 117, 0.05);
  color: rgba(0, 0, 0, 1);
}

&:hover {
  background: rgba(0, 0, 0, 0.05);
}
}

/* transition: transform .4s ease-in-out !important; */
`;